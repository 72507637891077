<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { removeAVSessionID } from 'src/boot/axios'

export default {
  name: 'OidcCallback',
  computed: {
    ...mapGetters('persisted', ['authRedirectUri', 'showEAIVPage']),
  },
  beforeMount() {
    if (
      this.$route?.query &&
      Object.values(this.$route.query).length &&
      this.showEAIVPage == true
    )
      this.$store.commit('persisted/SET_SHOW_EAIV_PAGE', false)

    if (!this.isCordova) {
      this.$oidc
        .signinRedirectCallback()
        .then(() => {
          this.$store.commit('common/SET_APP_LOADED', false)
        })
        .catch((e) => {
          let behindError = e?.message?.indexOf('iat is in the future') > -1,
            aheadError = e?.message?.indexOf('exp is in the past') > -1

          if (behindError || aheadError) {
            this.$store.commit('common/UPDATE_STATE', {
              key: behindError
                ? 'showDateTimeBehindError'
                : 'showDateTimeAheadError',
              val: true,
            })
          }

          // this.$router.push({ name: 'Home' }).catch(() => {})
        })
        .finally(() => {
          let routerPromise
          if (this.authRedirectUri) {
            routerPromise = this.$router.push({
              path: this.authRedirectUri,
            })
          } else {
            routerPromise = this.$router.push({ name: 'Home' })
            // Clear EAIV Session After Login
            // removeAVSessionID()
            // this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
            this.$store.commit('persisted/SET_EAIV_VERIFICATION_STATUS', false)
          }
          routerPromise
            .catch(() => {})
            .finally(() => {
              this.setDetails()
            })
        })
    } else {
      let routerPromise
      if (this.authRedirectUri) {
        routerPromise = this.$router.push({
          path: this.authRedirectUri,
        })
      } else {
        routerPromise = this.$router.push({ name: 'Home' })
        // Clear EAIV Session After Login
        // removeAVSessionID()
        // this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
        this.$store.commit('persisted/SET_EAIV_VERIFICATION_STATUS', false)
      }
      routerPromise
        .catch(() => {})
        .finally(() => {
          this.setDetails()
          hideShowFullSpinner(false)
        })
    }
  },
  methods: {
    setDetails() {
      this.$store.commit('persisted/SET_AUTH_REDIRECT_URI', null)
      if (this.isCordova && typeof FirebasePlugin !== 'undefined') {
        FirebasePlugin.getToken(async (token) => {
          let deviceType = this.$q.platform.is.android ? 1 : 2
          let params = {
            deviceId: device.uuid,
            deviceType: deviceType,
            token: token,
          }
          await this.$store.dispatch('auth/setFCMToken', params)
        })
      }
    },
  },
}
</script>
